<template>
  <b-modal
    id="template-goal-modal"
    ref="template-goal-my-modal"
    :title="$t('TEMPLATE_GOAL')"
    :ok-title="goal.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="validationRules">
      <b-form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('SOURCE')"
              label-for="id-source"
            >
              <!-- checkbox button  -->
              <b-form-group>
                <b-form-radio-group
                  id="id-source"
                  v-model="goal.src"
                  button-variant="outline-primary"
                  :options="optionsSource"
                  buttons
                  name="radios-btn-default"
                  @change="setGoalType()"
                />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('DESCRIPTION')"
              label-for="id-description"
              :invalid-feedback="$t('DESCRIPTION_REQUIRED')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('DESCRIPTION')"
                rules="required"
              >
                <b-form-input
                  id="id-description"
                  v-model="goal.description"
                  maxlength="120"
                  :placeholder="$t('DESCRIPTION')"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="goal.src==='kpi'"
            cols="12"
          >
            <b-form-group
              :label="$t('KPI')"
              label-for="id-kpi"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('KPI')"
                rules="required"
              >
                <v-select
                  id="id-kpi"
                  v-model="kpi"
                  label="name"
                  :options="kpis"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="goal.src==='hab'"
            cols="12"
          >
            <b-form-group
              :label="$t('HABIT')"
              label-for="id-habit"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('HABIT')"
                rules="required"
              >
                <v-select
                  id="id-habit"
                  v-model="habit"
                  label="taskbeskrivning"
                  :placeholder="$t('SELECT_HABIT')"
                  :options="habits"
                  :clearable="false"
                  menu-props="auto"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="goal.src==='beh'"
            cols="12"
          >
            <b-form-group
              :label="$t('BEHAVIOUR')"
              label-for="id-behaviour"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('BEHAVIOUR')"
                rules="required"
              >
                <v-select
                  id="id-behaviour"
                  v-model="behaviour"
                  label="subtaskstring"
                  :options="behaviours"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('FLOAT_VALUE')"
              label-for="id-floatvalue"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('FLOAT_VALUE')"
                rules="required"
              >
                <b-form-input
                  id="id-floatvalue"
                  v-model="goal.floatvalue"
                  type="number"
                  :placeholder="$t('FLOAT_VALUE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('GOALS_TYPE')"
              label-for="id-goalType"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('GOALS_TYPE')"
                rules="required"
              >
                <v-select
                  id="id-goalType"
                  v-model="goal_type"
                  :placeholder="$t('SELECT_GOAL_TYPE')"
                  label="name"
                  :options="goalTypeOptions"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal,
  VBModal, BRow, BCol, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BModal,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'viewGoal',
  },
  props: {
    viewGoal: {
      type: Object,
      default: () => {},
    },
    behaviours: {
      type: Array,
      default: () => [],
    },
    habits: {
      type: Array,
      default: () => [],
    },
    kpis: {
      type: Array,
      default: () => [],
    },
    fetchTemplateGoals: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      goal: {
        ID: 0,
        template_id: 0,
        category_id: 0,
        description: '',
        competition_KPI_ID: 0,
        floatvalue: 0,
        goal_type: '',
        subject: '',
        src: 'hab',
        reached_datetime: '',
      },
      goal_type: '',
      habit: '',
      kpi: '',
      behaviour: '',
      optionsSource: constants.KPI_GOALS.OPTIONS_SOURCE,
      goalTypeKpi: constants.KPI_GOALS.GOAL_TYPE_KPI,
      goalTypeHabit: constants.KPI_GOALS.GOAL_TYPE_HABIT,
      goalTypeOptions: constants.KPI_GOALS.GOAL_TYPE_OPTIONS,
      required,
    }
  },
  watch: {
    viewGoal: {
      handler() {
        if (this.viewGoal) {
          this.goal = this.viewGoal
          this.setGoalType()
          this.setObject()
          this.goal_type = this.goalTypeOptions.find(i => i.id === this.viewGoal.goal_type)
        } else {
          this.resetModal()
          this.setGoalType()
        }
      },
    },
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('template', ['createTemplateTargetKpi', 'updateTemplateTargetKpi']),
    setGoalType() {
      this.goal_type = ''
      if (this.goal.src === 'kpi') {
        this.goalTypeOptions = JSON.parse(JSON.stringify(this.goalTypeKpi))
      } else {
        this.goalTypeOptions = JSON.parse(JSON.stringify(this.goalTypeHabit))
      }
    },
    setObjectId() {
      if (this.goal.src === 'kpi') {
        this.goal.competition_KPI_ID = this.kpi.ID
      } else if (this.goal.src === 'hab') {
        this.goal.competition_KPI_ID = this.habit.taskID
      } else {
        this.goal.competition_KPI_ID = this.behaviour.subtaskID
      }
    },
    setObject() {
      if (this.goal.src === 'kpi') {
        this.kpi = this.kpis.find(i => i.ID === this.goal.competition_KPI_ID)
      } else if (this.goal.src === 'hab') {
        this.habit = this.habits.find(i => i.taskID === this.goal.competition_KPI_ID)
      } else {
        this.behaviour = this.behaviours.find(i => i.subtaskID === this.goal.competition_KPI_ID)
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.goal_type = ''
      this.habit = ''
      this.kpi = ''
      this.behaviour = ''
      this.goal = {
        ID: 0,
        template_id: 0,
        category_id: 0,
        description: '',
        competition_KPI_ID: 0,
        floatvalue: 0,
        goal_type: '',
        subject: '',
        src: '',
      }
    },
    handleSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.goal.goal_type = this.goal_type.id
          this.goal.subject = 'all'
          this.setObjectId()
          if (this.goal.id) {
            this.updateTemplateTargetKpi(this.goal).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GOALS_UPDATED'))
                this.fetchTemplateGoals({ ID: this.goal.template_id })
                this.resetModal()
                this.$bvModal.hide('template-goal-modal')
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            delete this.goal.ID
            this.goal.template_id = this.viewGoal.template_id
            this.goal.category_id = this.viewGoal.category_id
            this.createTemplateTargetKpi(this.goal).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GOALS_CREATED'))
                this.fetchTemplateGoals({ ID: this.goal.template_id })
                this.resetModal()
                this.$bvModal.hide('template-goal-modal')
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
