import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import constants from '@/constants/static.json'

export default function useGoalsList() {
  // Use toast
  const { PER_PAGE_OPTIONS, PER_PAGE } = constants
  const refGoalsListTable = ref(null)

  const emptyGoals = {
    id: 0,
    compid: 0,
    description: '',
    competition_KPI_ID: 0,
    floatvalue: 0,
    goal_type: '',
    subject: 'all',
    src: 'hab',
  }

  // Table Handlers
  const goalsTableColumns = [
    { key: 'Description', sortable: false },
    { key: 'Source', sortable: false },
    { key: 'Value', sortable: false },
    { key: 'GoalType', sortable: false },
    { key: 'Actions' },
  ]
  const perPage = ref(PER_PAGE)
  const totalGoals = ref(0)
  const goals = ref([])
  const habits = ref([])
  const behaviours = ref([])
  const kpis = ref([])
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refGoalsListTable.value ? refGoalsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGoals.value,
    }
  })

  const refetchData = () => {
    refGoalsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const resolveGoals = data => {
    if (data === 'kpi') {
      return 'KPI'
    }
    if (data === 'hab') {
      return 'Habit'
    }
    if (data === 'beh') {
      return 'Behaviour'
    }
    return ''
  }

  const countingHabits = data => {
    habits.value = []
    behaviours.value = []
    data.forEach(t => {
      const task = { ...t.task }
      delete task.task_subtasks
      habits.value.push(task)
      t.task.task_subtasks.forEach(subTasks => {
        // eslint-disable-next-line no-plusplus
        behaviours.value.push(subTasks.subtask)
      })
    })
  }

  const fetchTaskSubTask = id => {
    if (id) {
      store.dispatch('app-game/fetchTaskSubTask',
        id)
        .then(response => {
          const { result } = response.data
          countingHabits(result)
        }).catch(() => {})
    }
  }

  const fetchKpis = id => {
    store.dispatch('app-game/fetchKpis',
      id)
      .then(response => {
        const { result } = response.data
        kpis.value = result
      })
      .catch(() => {})
  }

  const fetchGoals = id => {
    store.dispatch('app-game/fetchGoals',
      id)
      .then(response => {
        const { data } = response.data
        goals.value = data
        totalGoals.value = data.length
        fetchTaskSubTask(id)
        fetchKpis(id)
      })
      .catch(() => {})
  }

  return {
    fetchGoals,
    resolveGoals,
    goalsTableColumns,
    perPage,
    currentPage,
    totalGoals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGoalsListTable,
    statusFilter,
    refetchData,
    emptyGoals,
    goals,
    habits,
    behaviours,
    kpis,
  }
}
